#root,body,html {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  min-height: 100%
}

#root {
  flex-shrink: 0;
  flex-basis: auto;
  flex-grow: 1;
  display: flex;
  flex: 1
}

html {
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
  height: calc(100% + env(safe-area-inset-top))
}

body {
  display: flex;
  overflow-y: auto;
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar
}